import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./BillBlast.module.css";
import Title from "../../../../components/Title";
import Carrousel from "../../../../components/Carrousel";
import Carousel from 'react-multi-carousel';
import BillBlastPhoto1 from "../../../../res/blast/portrait.png"
import BillBlastPhoto2 from "../../../../res/Photos site/Bill Blast/Bill Blast 2.jpg"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/blast/past-present-1.png"
import PastPresent2 from "../../../../res/blast/past-present-2.jpg"
import PastPresent3 from "../../../../res/blast/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "BillBlast",
  name: "BillBlast",
  description: "William Cordero alias Bill Blast est un artiste américain. C’est l'un des plus grands graffeurs new-yorkais de la période des années 1980. Bill Blast est né en 1964 et a grandi à Harlem. Au début des années 1980, il faisait partie d'un groupe de graffeurs du métro qui sont passés en galeries en créant ainsi le mouvement du post-graffiti. Bill Blast a principalement peint des murs de terrains de baseball à New York et a en fait très peu de trains à son actif. En 1981, il entre à la High School of Art and Design. Après avoir été consultant pour le film Beat Street de 1984 réalisé par Stan Lathan, il intègre la Parsons School of Design et commence à peindre sur toile. Bill Blast expose ses oeuvres à échelle internationale depuis l'âge de dix-neuf ans (Hollande, Italie, USA…).",
  pdp: BillBlastPhoto1,
  alt_pdp: "Photo de profil de BillBlast.",
  photos: [
    { src: PastPresent1, name: "BillBlast" },
    { src: PastPresent2, name: "BillBlast" },
    { src: PastPresent3, name: "BillBlast" },
  ],
  citations: []
};

const BillBlast = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
       <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>BILL BLAST <h2 style={{paddingLeft: "16px"}}>(William Cordero - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1964</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
                   <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p>- School of Visual Arts, New York, USA.</p>
          <p>- Parsons School of Design, New York, USA.</p>
          <br/>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Blast on the 4 Line: Futurism and Symbolism in Urban Art, Graffiti virtual gallery, Israel. </p>
          <br/>
          <p style={{fontWeight: "bold"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight:"bold"}}>2022</p>
          <p>- New York Street Style : création des années 80, Ghost Galerie, Paris, juillet - septembre 2022.</p>
          <br />
          <p style={{fontWeight:"bold"}}>2021</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.</p>
          <br />
          <p style={{fontWeight:"bold"}}>2016</p>
          <p>- Friends of Antonio, El Museo del Barrio, New York, USA, November 3rd - 27th.</p>
          <br/>
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Graffiti: New York 80's, Galerie Jérôme de Noirmont, Paris, France, May 27th - July 20th. (catalogue)</p>
          <br/>
          <br/>
          <p style={{fontWeight: "bolder"}}>PRIVATE COLLECTIONS</p>
          <br />
          <p>- Henk Pijnenburg, Molenrijnselaan, The Netherlands.</p>
          <p>- Yaki Kornblit, Amsterdam, The Netherlands.</p>
          <p>- Gallery Speerstra, Bursins, Switzerland.</p>
          <p>- Gallizia Alain-Dominique, Boulogne-Billancourt, France.</p>
          <br/>
          <br/>
          <div className={More} id="more">
          <p style={{fontWeight: "bolder"}}>PUBLICATIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Martha Cooper & Joseph Sciorra, Memorial Wall Art.</p>
          <br/>
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Galerie Jérôme de Noirmont, Graffiti New York Années 80, Paris, France, May 27th - July 20th</p>
          <br/>
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Groninger Museum, Coming From The Subway,</p>
          <br/>
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- CHALFANT H. and PRIGOFF J., Spraycan Art, Thames and Hudson, London, UK.</p>
          <br/>
          <br/>
          <p style={{fontWeight: "bolder"}}>RESIDENCES</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p id="end">- Joan Of Arc Junior High School, New York City, USA.</p>
        </div>
        </ul>
        <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      <div className={PhotosWrapper}>
        <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default BillBlast;