// extracted by mini-css-extract-plugin
export var ArtistDescription = "BillBlast-module--ArtistDescription--JNGT8";
export var ArtistInfos = "BillBlast-module--ArtistInfos--rCqsz";
export var ButtonWrapper = "BillBlast-module--ButtonWrapper --ieNBN";
export var CardWrapper = "BillBlast-module--CardWrapper--rdbi6";
export var CarrouselWrapper2 = "BillBlast-module--CarrouselWrapper2--vxY0A";
export var Citations = "BillBlast-module--Citations--s6VYe";
export var DescriptionWrapper = "BillBlast-module--DescriptionWrapper--lT+ic";
export var ImageWrapper = "BillBlast-module--ImageWrapper----iTE";
export var LinkWrapper = "BillBlast-module--LinkWrapper--bvkJV";
export var MobileProtrait = "BillBlast-module--MobileProtrait--w6t+I";
export var More = "BillBlast-module--More--bozIB";
export var MoreButton = "BillBlast-module--MoreButton---R7VY";
export var NameWrapper = "BillBlast-module--NameWrapper--wjJ93";
export var PdpWrapper = "BillBlast-module--PdpWrapper--aHBNe";
export var PhotosWrapper = "BillBlast-module--PhotosWrapper--cz4d4";
export var ProfilWrapper = "BillBlast-module--ProfilWrapper--y1mYh";
export var TitleWrapper = "BillBlast-module--TitleWrapper--UG3eo";
export var Wrapper = "BillBlast-module--Wrapper--RE38V";